import React, { useEffect, useRef } from 'react';
import useScript from '../../../hooks/useScript';
import useStyleSheet from '../../../hooks/useStyleSheet';
import LazyLoad from 'react-lazyload';

declare global {
  interface Window {
    Prism: {
      highlightElement: (element: HTMLElement) => void;
    };
  }
}

function EnhancedMarkdownCode({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element {
  return (
    <LazyLoad placeholder={<pre>{children}</pre>}>
      <EnhancedMarkdownCodeInner>{children}</EnhancedMarkdownCodeInner>
    </LazyLoad>
  );
}

function EnhancedMarkdownCodeInner({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element {
  const ref = useRef<HTMLPreElement>(null);
  const prismHasLoaded =
    useScript('https://assets2.rpglogs.com/libs/prism/prism.min.js') ===
    'ready';
  useStyleSheet(
    'https://assets2.rpglogs.com/libs/prism/themes/vscode.min.css?v=3'
  );

  useEffect(() => {
    if (prismHasLoaded && ref.current) {
      const codeElement = ref.current.querySelector('code');
      if (codeElement) {
        window.Prism.highlightElement(codeElement);
      }
    }
  }, [prismHasLoaded]);

  return <pre ref={ref}>{children}</pre>;
}

export default EnhancedMarkdownCode;
