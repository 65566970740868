import React, { CSSProperties, ReactNode } from 'react';
import useTheme from '../../contexts/useTheme';

type BodyProps = {
  style?: BodyStyle;
  cssStyle?: CSSProperties;
  className?: string;
  children?: ReactNode;
};

export type BodyStyle =
  | 'v1'
  | 'v2'
  | 'v2-faded-1'
  | 'article'
  | 'v3'
  | 'v3-compact';

export default function Body({
  style,
  cssStyle,
  className,
  children,
  ...attributes
}: BodyProps): JSX.Element {
  const theme = useTheme();
  style = style || theme.bodyStyle;
  className = `${className ? className + ' ' : ''}body body--style-${style}`;

  return (
    <div className={className} style={cssStyle} {...attributes}>
      {children}
    </div>
  );
}
